/* Raleway */
@font-face {
  font-display: swap;
  font-family: "Raleway-SemiBold";
  font-weight: 600;
  src: url("/common/fonts/Raleway-SemiBold.woff2") format("woff2"), /* Modern Browsers */ 
    url("/common/fonts/Raleway-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Roboto */
@font-face {
  font-display: swap;
  font-family: "Roboto-Regular";
  font-weight: 400;
  src: url("/common/fonts/Roboto-Regular.woff2") format("woff2"),
    url("/common/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Medium";
  font-weight: 500;
  src: url("/common/fonts/Roboto-Medium.woff2") format("woff2"),
    url("/common/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Bold";
  font-weight: 700;
  src: url("/common/fonts/Roboto-Bold.woff2") format("woff2"),
    url("/common/fonts/Roboto-Bold.ttf") format("truetype");
}
